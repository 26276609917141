import * as ReactDOM from "./_snowpack/pkg/react-dom.js";
import * as React from "./_snowpack/pkg/react.js";
function App() {
  const [price, setPrice] = React.useState(0);
  const [priceBTC, setPriceBTC] = React.useState(0);
  const [input, setInput] = React.useState(1400);
  const runOnce = [];
  React.useEffect(() => {
    const fetchPrice = () => {
      fetch("https://api1.binance.com/api/v3/ticker/price?symbol=RVNUSDT").then((response) => response.json()).then((data) => {
        setPrice(parseFloat(data.price));
        document.title = parseFloat(data.price).toLocaleString() + "$ RVN";
      });
      fetch("https://api1.binance.com/api/v3/ticker/price?symbol=RVNBTC").then((response) => response.json()).then((data) => {
        setPriceBTC(parseFloat(data.price));
      });
    };
    fetchPrice();
    const interval = setInterval(fetchPrice, 20 * 1e3);
    return () => {
      clearInterval(interval);
    };
  }, runOnce);
  let dollarValue = input > 0 && input.toLocaleString();
  let ravenValue = "";
  if (input > 0) {
    if (input > 3) {
      ravenValue = Math.round(input / price).toLocaleString();
    } else {
      ravenValue = (input / price).toFixed(3).toLocaleString();
    }
  }
  if (input < 0) {
    ravenValue = "";
    dollarValue = "";
  }
  const ONE_HUNDRED_MILLIONS = 1e8;
  const sats = priceBTC && Math.round(priceBTC * ONE_HUNDRED_MILLIONS);
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "ravencoin-price-calculator__main-container ravencoin-price-calculator__glass-container"
  }, /* @__PURE__ */ React.createElement("h1", null, "Calculator"), /* @__PURE__ */ React.createElement("div", {
    style: {position: "relative", top: "-30px"}
  }, "Ravencoin"), /* @__PURE__ */ React.createElement("div", {
    className: "ravencoin-price-calculator__subtitle"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("small", null, " $", price && price.toFixed(3))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("small", null, " SATS ", sats))), /* @__PURE__ */ React.createElement("section", {
    className: "ravencoin-price-calculator__glass-container"
  }, /* @__PURE__ */ React.createElement("h5", {
    className: "ravencoin-price-calculator__cta"
  }, "Enter price in USD"), /* @__PURE__ */ React.createElement("input", {
    className: "ravencoin-price-calculator__input",
    type: "number",
    value: input,
    onChange: (event) => {
      const str = event.target.value;
      const n = parseFloat(str);
      setInput(n);
    }
  }), /* @__PURE__ */ React.createElement("table", {
    className: "ravencoin-price-calculator__table "
  }, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", null), /* @__PURE__ */ React.createElement("th", null))), /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", null, "$"), /* @__PURE__ */ React.createElement("td", null, " ", dollarValue)), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", null, "RVN"), /* @__PURE__ */ React.createElement("td", null, ravenValue))))), /* @__PURE__ */ React.createElement("footer", {
    className: "ravencoin-price-calculator__footer"
  }, "Brought to you by Raven Rebels. ", /* @__PURE__ */ React.createElement("br", null), "Feel free to give us 💙 over at", " ", /* @__PURE__ */ React.createElement("a", {
    href: "https://twitter.com/RavenRebels",
    className: "ravencoin-price-calculator__footer-link"
  }, "Twitter"), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("small", null, "Live prices from", " ", /* @__PURE__ */ React.createElement("a", {
    href: "http://binance.com/",
    className: "ravencoin-price-calculator__footer-link"
  }, "Binance"))))));
}
ReactDOM.render(/* @__PURE__ */ React.createElement(App, null), document.getElementById("app"));
